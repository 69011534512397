<template>
  <div
    id="clauseSupply"
    style="background-color: #eff3f5;height: 100%;width: 100%;overflow: scroll"
    align="center"
    overflow="scroll"
  >
    <a-card style="background-color: white;width: 1100px;margin-top: 50px;padding-left: 20px;padding-right: 20px">
      <div lang="zh-CN" link="#000080" vlink="#800000" dir="ltr">
        <div style="margin-top: 20px">
          <h3>
            <p align="center" style="font-size: 26px">{{ themeData.saasMerNameZh }}服务条款补充协议</p>
          </h3>
        </div>
        <p align="left" lang="zh-TW" class="cjk" style="margin-top: 50px;line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font><font><span lang="zh-CN">本补充协议旨在明确本跨境支付结算服务由</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}
            LIMITED</font></span></font><font><span lang="zh-CN">（简称“</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>{{ themeData.saasMerNameZh }}”</font></span></font><font><span lang="zh-CN">）、及其境内合作持牌支付机构（需同时持有人民银行支付业务许可证和跨境人民币资质）（简称“合作机构”）共同为您提供，本补充协议就上述跨境支付结算服务（简称“本服务”）有关事项进行约定。</span></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">请您仔细阅读本补充协议（特别是字体加粗部分）。如您对本补充协议内容及页面提示信息有疑问，请勿进行下一步操作</b></span></font></font><font><font>。您可通过</font></font><font face="Calibri, serif"><span lang="en-US"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></font><font><font>客服渠道进行咨询，以便我们为您解释和说明。</font></font><font><font><b color="black">如</b></font></font><font><font><b color="black">您通过网络页面点击确认或以我们认可的其他方式选择接受本补充协议的，即表示您就本跨境支付结算服务与</b></font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span></font><font><font><b color="black">已达成本补充协议并同意接受本补充协议的全部约定内容。</b></font></font></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">1．定义<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>1.1</font></font></span><font><span lang="zh-CN">跨境交易平台</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>/</font></span></font><font><span lang="zh-CN">平台：指您申请入驻、为您与用户跨境交易提供网络经营场所、交易撮合等服务的境外电子商务交易平台。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>1.2</font></font></span><font><span lang="zh-CN">交易：您与平台用户所进行的符合中国人民银行、中国国家外汇管理局相关规定并且许可合作机构提供服务的跨境货物贸易或服务贸易。</span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm"><a
                                                                                                                        name="_Hlk35475956"
                                                                                                                      />
          <font face="Calibri, serif"><span lang="en-US"><font><font>1.3</font></font></span><font><span
            lang="zh-CN"
          >跨境支付结算服务：</span></font></font><font><font><span lang="zh-CN">您提出服务申请，</span></font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span
            lang="zh-CN"
          >、</span></font></font><font><font><span
            lang="zh-CN"
          >合作机构</span></font></font><font><font><span lang="zh-CN">通过审核后，向您提供的境外收款、境外结算、跨境支付结算和境内结算等服务，以便您通过平台在全球范围内接收资金，或将已接收资金跨境结算至中国境内或全球范围内您指定的账户。</span></font></font>
        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">2．服务内容及要求<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>2.1</font></font></span></font><font><font><span
            lang="zh-CN"
          >针对您与平台用户跨境交易所得资金，</span></font></font><font face="Calibri, serif"><span lang="en-US"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></font><font><font><span
            lang="zh-CN"
          >在各自属地法律法规授权许可的业务范围内，为您提供跨境支付结算服务，其中合作机构提供跨境结算、中国境内结算服务，</span></font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font>{{ themeData.saasMerNameZh }}</font></font></span></font><font><font><span lang="zh-CN">提供</span></font></font><font><font><span lang="zh-CN">或由其合作银行提供货币兑换服务、境外收款服务、</span></font></font><font><font><span lang="zh-CN">境外结算服务</span></font></font><font><font><span
            lang="zh-CN"
          >。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>2.2
          </font></font></span><font><span lang="zh-CN">您申请开通服务，</span></font></font><font><font><span lang="zh-CN">需向</span></font><font face="Calibri, serif"><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构提供必要的基本信息、资质证明信息等资料，包括不限于您的营业执照信息、身份证件信息（如您为自然人时）、法定代表人信息、</span></font></font><font><font>证件有效期</font></font><font><font><span
            lang="zh-CN"
          >、</span></font></font><font><font><span lang="zh-CN">联系方式、收款银行账户信息等及相应证件影印件，具体以您开通服务需提交的信息资料为准。如</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">先向您搜集上述信息资料，您同意</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">收集后将申请信息资料传递至合作机构。</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构收到信息资料并审核通过后，为您提供本服务</span></font></font><font><font><span lang="zh-CN">。合作机构为您提供本服务将按照</span></font></font><font><font><span lang="zh-CN">特约商户对您进行管理，具体要求以您与合作机构另行签订的支付服务协议约定为准</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>2.3</font></font></span><font><span
            lang="zh-CN"
          >开通服务后，您可以向</span></font><font face="Calibri, serif"><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构发起支付结算指令并提供交易信息或者授权合作机构和</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span
            lang="zh-CN"
          >向您指定的第三方收集交易信息（包括但不限于商品或服务名称、数量、货物种类或服务种类、订单号码、订单时间、交易币种、金额、交易双方及国别）</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>,
          </font></span></font><font><span lang="zh-CN">以及</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构要求提供的证明贸易背景的相关单证材料。</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构根据您的支付结算指令将资金结算至</span></font></font><font><font><span lang="zh-CN">中国境内</span></font></font><font><font><span
            lang="zh-CN"
          >您指定的</span></font></font><font><font><span
            lang="zh-CN"
          >人民币</span></font></font><font><font><span
            lang="zh-CN"
          >账户。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm"><a
                                                                                                                        name="OLE_LINK3"
                                                                                                                      />
          <font face="Calibri, serif"><span lang="en-US"><font><font>2.4</font></font></span></font><font><font><span
            lang="zh-CN"
          >合作机构通过</span></font></font><font><font>合作银行为您进行</font></font><font><font><span lang="zh-CN">跨境支付结算</span></font></font><font><font>业务处理，具体以合作机构与您另行签订的支付服务协议约定为准</font></font><font><font><span
            lang="zh-CN"
          >。</span></font></font></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">3. 汇率与国际收支申报<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>3.1
          </font></font><font><font>{{ themeData.saasMerNameZh }}</font></font></span></font><font><font><span lang="zh-CN">或合作机构</span></font></font><font><font>为您提供</font></font><font><font><span
            lang="zh-CN"
          >本服务，会向您明示</span></font></font><font><font>由合作银行提供的汇率标价</font></font><font><font><span
            lang="zh-CN"
          >。您知悉并理解</span></font></font><font><font>因汇率变动产生的损失需由您自行承担</font></font><font><font><span lang="zh-CN">，</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">或合作机构</span></font></font><font><font>不对汇率失变动导致的</font></font><font><font><span
            lang="zh-CN"
          >损失</span></font></font><font><font>承担相应责任。</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>3.2
          </font></font></span></font><font><font><span lang="zh-CN">针对为您提供的跨境支付结算服务，合作机构将根据相关法律法规要求向中国人民银行或国家外汇管理局进行数据报送，</span></font></font><font><font>具体以合作机构与您另行签订的支付服务协议约定为准</font></font><font><font><span lang="zh-CN">。</span></font></font></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">4. 服务费用<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><span style="text-transform: uppercase"><font><font><b color="black">4.1</b></font></font></span><font><span
            lang="zh-CN"
          ><b color="black">合作机构提供本服务暂不向您收取相应服务费。但</b></span></font></span></font><span
            style="text-transform: uppercase"
          ><font><font><span lang="zh-CN"><b color="black">基于业务发展情况，</b></span></font></font></span><span
            style="text-transform: uppercase"
          ><font><font><span
            lang="zh-CN"
          ><b color="black">合作机构保留</b></span></font></font></span><span style="text-transform: uppercase"><font><font><span lang="zh-CN"><b color="black">向您收取合理的交易服务费权利，并可根据业务发展需要对服务费进行调整。如收取服务费用的，合作机构会向您明示费用标准（含调整后的标准），具体以服务页面所列的收费公告或以其他方式列明的收费标准为准。</b></span></font></font></span>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><span style="text-transform: uppercase"><font><font><b color="black">4.2</b></font></font></span><font><span
            lang="zh-CN"
          ><b color="black">本服务项下</b></span></font><font face="Calibri, serif"><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span
            lang="zh-CN"
          ><b color="black">向您收取的服务费用由您和</b></span></font><font face="Calibri, serif"><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span
            lang="zh-CN"
          ><b color="black">另行约定收取。</b></span></font></span></font></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">5. 您的权利和义务<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><span style="text-transform: uppercase"><font><font>5.1</font></font></span></span></font><font><font><span lang="zh-CN">您应当为根据中国法律法规规定具备相应民事行为能力的自然人、法人或其他组织，并已按照相关监管规定办理或者符合开展跨境交易或使用本服务所需的资质、登记、审批和条件等。如您未办理或者未符合的，</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构有权单方终止向您提供服务。
          </span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">5.2</b></font></font></span></font><font><font><b color="black">您保证您所提交的所有信息和资料</b></font></font><font><font><span lang="zh-CN"><b color="black">合法、</b></span></font></font><font><font><b color="black">真实、准确、完整，不存在违反法律、法规或涉嫌侵犯他人权利情形，且您使用本服务应符合相关法律法规及监管部门的规定，否则</b></font></font><font><font><span lang="zh-CN"><b color="black">造成的损失需由</b></span></font></font><font><font><b color="black">您</b></font></font><font><font><span lang="zh-CN"><b color="black">自行</b></span></font></font><font><font><b color="black">承担。</b></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">如您提供信息发生变更，应在发生变更后五个工作日内书面通知</b></span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span
            lang="zh-CN"
          ><b color="black">、合作机构并提供变更后资料信息。</b></span></font></font><font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span
            lang="zh-CN"
          ><b color="black">、合作机构</b></span></font></font><font><font><b color="black">有权向您追究因您信息资料不真实、</b></font></font><font><font><span lang="zh-CN"><b color="black">不</b></span></font></font><font><font><b color="black">准确完整及违法违规行为对</b></font></font><font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span
            lang="zh-CN"
          ><b color="black">、合作机构</b></span></font></font><font><font><b color="black">产生的法律责任、不利影响或者其它损失。</b></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>5.3
          </font></font></span><font><span lang="zh-CN">您保证不以虚构交易、分拆等方式逃避监管，所提供的交易信息具有合法性、真实性、有效性、完整性和可追溯性，不向其他支付机构或银行重复提供该等信息用以交易信息的逐笔还原。</span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm"><a
                                                                                                                        name="OLE_LINK5"
                                                                                                                      /><a name="OLE_LINK4" />
          <font face="Calibri, serif"><span lang="en-US"><font><font>5.4</font></font></span></font><span style="text-transform: uppercase"><font><font><span lang="zh-CN">您</span></font></font></span><font><font>应保证合法、诚信经营，不从事非法经营（包括但不限于不正当竞争、虚假宣传、非法广告、商业欺诈或侵害其客户、消费者合法权益的活动）。</font></font><font><font><span lang="zh-CN">您在使用本服务时</span></font></font><font><font>应遵守相关各地法律法规</font></font><font><font><span
            lang="zh-CN"
          >，</span></font></font><font><font>遵守中国人民银行和国家外汇管理局等国家相关机构的有关管理规定，不得利用</font></font><font><font><span lang="zh-CN">本服务</span></font></font><font><font>进行任何违法违规活动，包括但不限于洗钱、套汇、恐怖融资等。</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">5.5
          </b></font></font></span></font><font><font><b color="black">您</b></font></font><font><font><span lang="zh-CN"><b color="black">不得利用本服务从事任何有违所在国家或地区的法律法规及相关规定所禁止的产品</b></span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><b color="black">/</b></font></span></font><font><span lang="zh-CN"><b color="black">服务的销售，部分禁止销售的产品</b></span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font><b color="black">/</b></font></span></font><font><span lang="zh-CN"><b color="black">服务范围见附录表格，合作机构有权根据监管规定及风险控制要求更新表格内容并及时通知您。</b></span></font></font><font />
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>5.6
          </font></font></span></font><font><font>您承诺不会将服务项下的任何权利和义务转让给第三方。</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>5.7
          </font></font></span><font><span lang="zh-CN">您理解并同意结算资金的具体到账时间取决于合作银行和中国境内支付清算系统业务安排。</span></font></font>
        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">6. {{ themeData.saasMerNameZh }}、合作机构权利和义务<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>6.1</font></font></span></font><font><font>您</font></font><font><font><span
            lang="zh-CN"
          >提出</span></font></font><font><font>服务申请</font></font><font><font><span lang="zh-CN">或发起支付结算指令</span></font></font><font><font>后</font></font><font><font><span
            lang="zh-CN"
          >，</span></font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></span></font><font><font>有权根据</font></font><font><font><span
            lang="zh-CN"
          >各自属地法律法规、</span></font></font><font><font>监管部门的相关要求进行审核。对于异常或者高风险业务以及审核未通过的服务申请，有权拒绝为您提供服务，</font></font><font><font><span lang="zh-CN">如</span></font></font><font><font>已收到</font></font><font><font><span lang="zh-CN">待</span></font></font><font><font>结算资金</font></font><font><font><span
            lang="zh-CN"
          >将</span></font></font><font><font>退回至原付款账户，由此引发的汇率变动损失和服务费用需由您承担。</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">6.2</b></font></font><font><font /></font></span></font><font><font><span
            lang="zh-CN"
          ><b color="black">为向您提供本服务，</b></span></font><font face="Calibri, serif"><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span lang="zh-CN"><b color="black">、合作机构需依据各自属地法律法规及监管规定履行外汇管理、反洗钱等合规要求，如您提供的信息和</b></span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><b color="black">/</b></font></span></font><font><span lang="zh-CN"><b color="black">或相关材料、交易行为不符合前述合规要求的，</b></span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span
            lang="zh-CN"
          ><b color="black">、合作机构有权要求您补全更新相应信息和</b></span></font><font face="Calibri, serif"><span lang="en-US"><font><b color="black">/</b></font></span></font><font><span
            lang="zh-CN"
          ><b color="black">或相关材料，并中止或终止向您提供服务。</b></span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">6.3</b></font></font></span><font><span lang="zh-CN"><b color="black">为了符合相关法律法规及监管要求，您同意合作机构和</b></span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span lang="zh-CN"><b color="black">在监管和本服务要求的必要范围内向合作银行和</b></span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font><b color="black">/</b></font></span></font><font><span lang="zh-CN"><b color="black">或相关监管部门</b></span></font></font><font><font><b color="black">提供您的身份信息及交易信息，以便完成信息申报、监管报告并为您提供跨境支付结算服务。</b></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">6.4</b></font></font></span></font><font><font><b color="black">您</b></font></font><font><font><span lang="zh-CN"><b color="black">申请</b></span></font></font><font><font><b color="black">本服务</b></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">递交的支付结算指令将成为</b></span></font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span lang="zh-CN"><b color="black">、合作机构</b></span></font></span></font><font><font><b color="black">为您处理交易的不可撤销的指令。</b></font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span lang="zh-CN"><b color="black">、合作机构</b></span></font></span></font><font><font><b color="black">有权按相关指令依据本协议约定对</b></font></font><font><font><span lang="zh-CN"><b color="black">结算资金</b></span></font></font><font><font><b color="black">进行处理</b></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">，且对按指令完成的结算资金处理免予承担责任，但因</b></span></font><font face="Calibri, serif"><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span lang="zh-CN"><b color="black">、合作机构故意或重大过失造成您损失的除外</b></span></font></font><font><font><b color="black">。</b></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">6.5</b></font></font></span><font><span lang="zh-CN"><b color="black">您使用本服务结算资金对应的跨境交易类型、跨境交易金额需符合</b></span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></span></font><font><span lang="zh-CN"><b color="black">、合作机构</b></span></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">各自属地法律法规及监管规定要求，对于不符合交易类型或者交易金额要求的交易，请您知悉并理解合作机构、合规机构无法为您提供本服务或者只能为您提供部分服务。</b></span></font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span
            lang="zh-CN"
          ><b color="black">、合作机构也有权对您使用本服务可结算资金设置单笔结算限额、累计结算限额，并有权基于政策、业务变化对结算限额进行调整，具体以服务页面提示为准。</b></span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>6.6
            {{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构有权根据风险防范，对疑似</span></font></font><font><font>洗钱、疑似违反制裁合规、疑似恐怖融资、逃汇和套汇、以及疑似违反本协议约定等交易，</font></font><font><font><span lang="zh-CN">或您销售的产品或提供的服务与相关法律、法规、规章或规定有所抵触时，</span></font></font><font><font><span lang="zh-CN">及时采取调查核实、延迟结算或终止（中止）服务等措施，</span></font></font><font><font><span
            lang="zh-CN"
          >并有权解除本协议和向有关监管机关或司法机关报告，相应损失及责任需由您自行承担。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>6.7</font></font></span></font><font><font>如您将本协议约定之外的</font></font><font><font><span lang="zh-CN">交易</span></font></font><font><font>资金</font></font><font><font><span
            lang="zh-CN"
          >通过本服务进行结算的，</span></font></font><font><font>或您要求提供的</font></font><font><font><span
            lang="zh-CN"
          >跨境</span></font></font><font><font>支付</font></font><font><font><span lang="zh-CN">结算</span></font></font><font><font>服务违反中国</font></font><font><font><span
            lang="zh-CN"
          >或相应属地法律</span></font></font><font><font>法规政策，</font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></font><font><font>有权拒绝和停止为您提供服务。</font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>6.8</font></font></span><font><span lang="zh-CN">本服务项下</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构仅为您提供跨境支付结算服务，除此以外不承担其它义务。</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span
            lang="zh-CN"
          >、合作机构不介入您与平台用户、交易对象的合作关系及任何合作上的权利义务关系，也无义务承担您与任何合作方的纠纷及损失赔偿责任、法律责任。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm"><a
                                                                                                                        name="OLE_LINK2"
                                                                                                                      /><a name="OLE_LINK1" />
          <font face="Calibri, serif"><span lang="en-US"><span style="text-transform: uppercase"><font><font>6.9
            {{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></span></font><font><font><span lang="zh-CN">将根据相关法律法规规定，</span></font></font><font><font>对您的保密信息采取严格的保密措施</font></font><font><font><span
            lang="zh-CN"
          >。</span></font></font><font><font>保密信息包括但不限于您的身份信息、支付信息、交易信息等。除用于履行本协议及获得您的授权外，</font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><span style="text-transform: uppercase"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span
            lang="zh-CN"
          >、合作机构</span></font></span></font><font><font>不得以其他目的使用保密信息。</font></font><font><font><span lang="zh-CN">您知晓并同意合作机构和</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">有权对您提交或者收集的信息按照法律法规和各自属地监管部门要求予以保存。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>6.10
            {{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></font><font><font>不</font></font><font><font><span
            lang="zh-CN"
          >会</span></font></font><font><font>利用</font></font><font><font><span lang="zh-CN">本</span></font></font><font><font>服务以及您的</font></font><font><font><span
            lang="zh-CN"
          >交易</span></font></font><font><font>结算资金从事任何违法、违规，侵权或犯罪活动。</font></font></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">7.知识产权<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>7.1
          </font></font></span><font><span lang="zh-CN">未经</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构书面许可，您不得擅自使用</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构的企业名称、商标、</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>LOGO</font></span></font><font><span lang="zh-CN">、中英文标识等。您不得在其所在地或他国注册与</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构相关、相似、相近的商标。
          </span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>7.2
          </font></font></span><font><span lang="zh-CN">本合同项下，您所销售的商品或提供的服务不得侵犯第三方的知识产权</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>,</font></span></font><font><span lang="zh-CN">由此产生的侵权责任需由您自行承担，与</span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构无关。</span></font></font>
        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">8．不可抗力和免责<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font><font><span lang="zh-CN">您知悉并理解，</span></font></font><font><font>因下列原因导致</font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></span></font><font><font>无法正常提供服务，</font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><span style="text-transform: uppercase"><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span
            lang="zh-CN"
          >、合作机构</span></font></span></font><font><font>免于承担责任：</font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>1</font></font></span><font>、</font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></span></font><font><font>的系统停机维护或升级；</font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>2</font></font></span><font>、因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>3</font></font></span><font>、您的电脑软硬件和通信线路、供电线路出现故障的；</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>4</font></font></span><font>、因您操作不当或通过非经我们授权或认可的方式使用</font></font><font><font><span
            lang="zh-CN"
          >本</span></font></font><font><font>服务的；</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>5</font></font></span><font>、因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因、第三方服务瑕疵或政府行为等原因。</font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font><font>尽管有前款约定</font></font><font><font><span
            lang="zh-CN"
          >，</span></font></font><font face="Calibri, serif"><span lang="en-US"><span
            style="text-transform: uppercase"
          ><font><font>{{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构</span></font></span></font><font><font>将采取合理行动积极促使服务恢复正常。</font></font>
        </p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p class="navigationTitle" align="left">9. 其他<br></p>
        <p class="cjk" align="center" style="line-height: 100%; margin-bottom: 3mm">
          <br>

        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>9.1
            {{ themeData.saasMerNameZh }}</font></font></span><font><span lang="zh-CN">、合作机构将不断努力为您提供更好的服务，但请您理解，在法律法规变化、监管机构要求、政策变化、风险管控、停机维护、网络攻击、电信故障等情况下，</span></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font>{{ themeData.saasMerNameZh }}</font></span></font><font><span lang="zh-CN">、合作机构可能单方暂停、中止或终止向您提供服务。</span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font><b color="black">9.2
            {{ themeData.saasMerNameZh }}</b></font></font></span></font><font><font><b color="black">可根据国家法律法规变化及服务内容升级、保护</b></font></font><font><font><span
            lang="zh-CN"
          ><b color="black">用户</b></span></font></font><font><font><b color="black">权益等需要，不时修改本补充协议，一旦相关内容发生变更，</b></font></font><font
            face="Calibri, serif"
          ><span lang="en-US"><font><font><b color="black">{{ themeData.saasMerNameZh }}</b></font></font></span><font><span lang="zh-CN"><b color="black">将会通过网站公告等方式通知您，上述变更自公告之日起</b></span></font><font face="Calibri, serif"><span
            lang="en-US"
          ><font><b color="black">7</b></font></span></font><font><span lang="zh-CN"><b color="black">个日历天后生效。如您不同意修改后的协议内容，您有权停止使用相关服务；协商一致的，也可另行变更</b></span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font><font><span lang="zh-CN"><b color="black">相关服务和对应协议内容。</b></span></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>9.3
          </font></font></span><font><span lang="zh-CN">若合作机构与您就本跨境支付结算服务另行签订支付服务协议，有关合作机构与您的权利义务等相关条款以双方另行签订的支付服务协议约定为准。</span></font></font>
        </p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><span style="text-transform: uppercase"><font><font>9</font></font></span><font><font>.4
          </font></font></span></font><font><font>本补充协议的成立、生效、履行和解释，</font></font><font><font>均适用中华人民共和国法律</font></font><font><font><b color="black">（</b></font></font><font><font>仅为本协议之目的，为避免疑义，不包括香港特别行政区、澳门特别行政区及台湾地区法律）。</font></font><font><font><b color="black">各方在履行本补充协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均可向被告住所地有管辖权的人民法院提起诉讼。</b></font></font></p>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; orphans: 2; widows: 2; margin-bottom: 0cm">
          <font face="Calibri, serif"><span lang="en-US"><font><font>9.5</font></font></span><font><span lang="zh-CN">本补充协议经各方盖章之日起生效，本协议一式贰份，双方各执壹份，具有同等法律效力。</span></font></font>
        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm"><a name="OLE_LINK13" /><a
          name="OLE_LINK14"
        />
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="cjk" align="justify" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>
        <p class="navigationTitle" align="left" style="margin-bottom: 3mm;">附录1-禁止的产品/服务清单<br></p>
        <left>
          <table width="731" cellpadding="10" cellspacing="0">
            <colgroup>
              <col width="20">

              <col width="669">

            </colgroup>
            <tbody>
              <tr>
                <td width="20" height="6" style="background: transparent; border: 1px solid #000000; padding: 0cm 0.19cm">
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >1</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法政治音像制品、出版物</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >2</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法反动卡片类制品和节目台</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >3</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">国家机密文件资料等</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >4</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >色情和粗俗的视听产品，渠道和出版物</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >5</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >色情和粗俗的色情服务</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >6</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">赌博</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >7</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >赌博设备和配件</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >8</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">彩票</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >9</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >毒品及相关的吸毒工具</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="29"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >10</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">所有类型的武器（包括匕首，枪械及配件，仿真枪，弹药和爆炸物）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >11</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >军用、警用物品</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >12</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法获得的所得物品或财产</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >13</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >剧毒物品和危险化学品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >14</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >甩棍、电棍、电击棍等其他物品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >15</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >解锁工具和配件</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >16</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >麻醉药品、精神类药品、处方药、无批号药品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >17</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >胎儿性别鉴定</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >18</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >催情药</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >19</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">在线销售的医疗服务，包括医疗咨询，催眠治疗，整形手术</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >20</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">黑客攻击服务或配件</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >21</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >恶意软件</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >22</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法办证或刻章</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >23</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">众筹</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >24</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >视频聊天服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >25</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >所有宗教网站，出版物或配件</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >26</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >网上公墓、网上祭祀等服务</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >27</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">销售个人信息（例如身份证信息）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >28</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >间谍设备及配件</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >29</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >侵犯个人隐私的服务或产品（例如在线活动监控）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >30</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">传销
                    </font></font><font face="Calibri, serif"><span lang="en-US"><font><font
                      size="2"
                      style="font-size: 10pt"
                    >(</font></font></span></font><font><font size="2" style="font-size: 10pt">金字塔传销和多层次营销</font></font><font
                      face="Calibri, serif"
                    ><span lang="en-US"><font><font
                      size="2"
                      style="font-size: 10pt"
                    >)
                    </font></font></span></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >31</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >黄金投资</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >32</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">来自</font></font></font><font><font><font size="2" style="font-size: 10pt"><span
                      lang="zh-CN"
                    >合作机构</span></font></font></font><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >账户的现金返还</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >33</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">假币</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >34</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法出售财务信息（例如银行账户，银行卡）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >35</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >股票和证券</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >36</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">基金</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >37</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >保险产品和平台</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >38</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >金融产品和服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >39</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">回扣或现金返还服务</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >40</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >与金融产品和信息交易相关的软件或产品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >41</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >单用途预付卡（包括礼品卡和其他储值卡）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >42</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >非法或未注册的筹款活动</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >43</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >外汇兑换服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >44</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >个人借贷服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >45</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >分期付款服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >46</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >交易在中华人民共和国境内发行的发票</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >47</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >交易或出售虚拟货币（例如比特币，莱特币）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >48</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >卫星天线</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >49</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >考古和文物</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >50</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">货币交易或分销（人民币和外币）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >51</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">假冒产品（食物类）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >52</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">在线销售烟草和香烟</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >53</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >烟花和鞭炮</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >54</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">原油</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >55</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >人体器官</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >56</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >代孕服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >57</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >替考、代考、代写论文服务</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >58</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >国家保护动植物</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >59</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >走私物品</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >60</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >未经许可销售活动门票（例如奥运会或世博会门票）</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >61</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">种子</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >62</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >不动产买卖</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >63</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >慈善组织</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >64</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >拍卖网站和服务</font></font></font></p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >65</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">典当</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >66</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font size="2" style="font-size: 10pt">抽奖</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="7"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >67</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >销售具有传染性和危险性疾病的动物，植物或产品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="29"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >68</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >销售源自传染病疫情爆发的地区的动物，植物或产品</font></font></font>
                  </p>
                </td>
              </tr>
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr />
              <tr>
                <td
                  width="20"
                  height="6"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; padding: 0cm 0.19cm"
                >
                  <p lang="zh-TW" class="cjk" align="right" style="orphans: 0; widows: 0">
                    <font face="Calibri, serif"><span lang="en-US"><font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >69</font></font></font></span></font></p>
                </td>
                <td
                  width="669"
                  valign="bottom"
                  style="background: transparent; border-top: none; border-bottom: 1px solid #000000; border-left: none; border-right: 1px solid #000000; padding-top: 0cm; padding-bottom: 0cm; padding-left: 0cm; padding-right: 0.19cm"
                >
                  <p align="left" lang="zh-TW" class="cjk" style="orphans: 0; widows: 0">
                    <font><font><font
                      size="2"
                      style="font-size: 10pt"
                    >促进非法集会活动的服务或产品</font></font></font>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </left>
        <p align="left" lang="zh-TW" class="cjk" style="line-height: 150%; margin-bottom: 0cm">
          <br>

        </p>

      </div>
    </a-card>
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue'
export default {
  name: 'ClauseSupply',
  setup () {
    const state = reactive({
      themeData: inject('$themeData')
    })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style scoped>
p.navigationTitle {
  font-size: 15px;
  color: black;
  font-weight: bold;
}
</style>
